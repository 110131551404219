<template>
  <b-modal
    id="category-edit-modal"
    title="Edit Product Category"
    centered
    hide-footer
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    :hide-header-close="true"
    @show="onModalShow"
  >
    <ValidationObserver v-slot="{ handleSubmit }">
      <CForm role="form" @submit.prevent="handleSubmit(updateCategory)">
        <ValidationProvider
          :rules="{ nameRequired: true }"
          v-slot="{ errors, invalid, validated }"
        >
          <CInput
            label="Name"
            placeholder="Enter category name"
            v-model="name"
            :isValid="!validated ? null : invalid ? false : true"
          >
            <template #invalid-feedback v-if="errors[0]">
              <div class="invalid-feedback">{{ errors[0] }}</div>
            </template>
          </CInput>
        </ValidationProvider>
        <ValidationProvider>
          <div class="form-group" role="group">
            <label>Parent Category</label>
            <v-select
              :options="categoryListOptions"
              :reduce="(cateItem) => cateItem.code"
              v-model="parentId"
            />
          </div>
        </ValidationProvider>
        <div
          v-if="errorMessage && errorMessage !== ''"
          class="text-center text-danger mb-2"
        >
          {{ errorMessage }}
        </div>
        <div class="text-center">
          <CButton variant="outline" color="primary" type="submit" class="mx-2"
            >Save</CButton
          >
          <CButton
            variant="outline"
            color="dark"
            type="button"
            class="mx-2"
            @click="$bvModal.hide('category-edit-modal')"
            >Cancel
          </CButton>
        </div>
      </CForm>
    </ValidationObserver>
    <CElementCover :opacity="0.8" v-if="loading" />
  </b-modal>
</template>

<script>
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { mapState } from "vuex";

export default {
  props: {
    currentCateId: {
      type: Number,
      required: true,
    },
  },

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  computed: {
    ...mapState({
      categoryList: (state) => state.productCategory.productCategoryList,
    }),

    categoryListOptions() {
      return this.categoryList
        .filter((cateItem) => cateItem.id !== this.currentCateId)
        .map((cateItem) => {
          return {
            code: cateItem.id,
            label: cateItem.name,
          };
        });
    },
  },

  data() {
    return {
      name: "",
      parentId: null,
      errorMessage: "",
      loading: false,
    };
  },

  methods: {
    onModalShow() {
      this.loading = true;
      this.resetForm();

      Promise.all([
        this.$store.dispatch("productCategory/getProductCategoryList"),
        this.$store.dispatch(
          "productCategory/findProductCategory",
          this.currentCateId
        ),
      ])
        .then((responses) => {
          let { success, data: cateItem } = responses[1].data;

          if (!success) {
            this.$bvModal.hide("category-edit-modal");
            this.$swal.fire({
              title: "Edit Category",
              text: "Have error while loading data",
              icon: "error",
            });
          } else {
            this.parentId = cateItem.parent_id;
            this.name = cateItem.name;
          }
        })
        .catch(() => {
          this.$bvModal.hide("category-edit-modal");
          this.$swal.fire({
            title: "Edit Category",
            text: "Have error while loading data",
            icon: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    resetForm() {
      this.name = "";
      this.parentId = null;
      this.errorMessage = "";
    },

    updateCategory() {
      this.loading = true;

      this.$store
        .dispatch("productCategory/updateProductCategory", {
          id: this.currentCateId,
          data: {
            name: this.name,
            parent_id:
              this.parentId && this.parentId !== 0 ? this.parentId : null,
          },
        })
        .then((res) => {
          let { success } = res.data;

          if (success) {
            this.resetForm();

            this.$bvModal.hide("category-edit-modal");
            this.$store.dispatch("productCategory/getProductCategoryTable");
          } else {
            this.errorMessage = "Update Category Failed!";
          }
        })
        .catch(() => {
          this.errorMessage = "Update Category Failed";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    extend("nameRequired", {
      ...required,
      message: () => "Category name is required",
    });
  },
};
</script>
