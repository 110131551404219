<template>
  <b-modal
    id="category-create-modal"
    title="Create New Product Category"
    centered
    hide-footer
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    :hide-header-close="true"
    @show="onModalShow"
  >
    <ValidationObserver v-slot="{ handleSubmit }">
      <CForm role="form" @submit.prevent="handleSubmit(createCategory)">
        <ValidationProvider
          :rules="{ nameRequired: true }"
          v-slot="{ errors, invalid, validated }"
        >
          <CInput
            label="サブカテゴリー名"
            placeholder="サブカテゴリー名を入力"
            v-model="name"
            :isValid="!validated ? null : invalid ? false : true"
          >
            <template #invalid-feedback v-if="errors[0]">
              <div class="invalid-feedback">{{ errors[0] }}</div>
            </template>
          </CInput>
        </ValidationProvider>
        <ValidationProvider>
          <div class="form-group" role="group">
            <label>カテゴリー</label>
            <v-select
              :options="categoryListOptions"
              :reduce="(cateItem) => cateItem.code"
              v-model="parentId"
            />
          </div>
        </ValidationProvider>
        <div
          v-if="errorMessage && errorMessage !== ''"
          class="text-center text-danger mb-2"
        >
          {{ errorMessage }}
        </div>
        <div class="text-center">
          <CButton variant="outline" color="primary" type="submit" class="mx-2">
            提出
          </CButton>
          <CButton
            variant="outline"
            color="dark"
            type="button"
            class="mx-2"
            @click="$bvModal.hide('category-create-modal')"
            >
            キャンセル
          </CButton>
        </div>
      </CForm>
    </ValidationObserver>
    <CElementCover :opacity="0.8" v-if="loading" />
  </b-modal>
</template>

<script>
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { mapState } from "vuex";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  computed: {
    ...mapState({
      categoryList: (state) => state.productCategory.productCategoryList,
    }),

    categoryListOptions() {
      return this.categoryList.map((cateItem) => {
        return {
          code: cateItem.id,
          label: cateItem.name,
        };
      });
    },
  },

  data() {
    return {
      name: "",
      parentId: null,
      errorMessage: "",
      loading: false,
    };
  },

  methods: {
    onModalShow() {
      this.loading = true;

      this.$store
        .dispatch("productCategory/getProductCategoryList")
        .finally(() => {
          this.loading = false;
        });
    },

    resetForm() {
      this.name = "";
      this.parentId = null;
      this.errorMessage = "";
    },

    createCategory() {
      this.loading = true;

      this.$store
        .dispatch("productCategory/createProductCategory", {
          name: this.name,
          parent_id: this.parentId,
        })
        .then((res) => {
          let { success } = res.data;

          if (success) {
            this.resetForm();

            this.$bvModal.hide("category-create-modal");
            this.$store.dispatch("productCategory/getProductCategoryTable");
          } else {
            this.errorMessage = "Create Category Failed!";
          }
        })
        .catch(() => {
          this.errorMessage = "Create Category Failed";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    extend("nameRequired", {
      ...required,
      message: () => "サブカテゴリー名を入力してください",
    });
  },
};
</script>
